
import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    signIn() {
      this.$router.push('/sign-in');
    },
  },
});
